import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/js/rem"
import VueQuillEditor from 'vue-quill-editor'
// require styles 导入富文本编辑器对应的样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
import Print from 'print-js';

import printPage from '@/utils/print.js'
Vue.use(printPage)
import vueEsign from "vue-esign"
Vue.use(vueEsign)


import { get, post,axiosURL } from './assets/js/axios.js'

import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import '../element-variables.scss'//主题色/换肤色

import './assets/index.css';
Vue.prototype.$get = get
Vue.prototype.$post = post
Vue.prototype.$URL = axiosURL

import 	MyTable from './components/Table.vue'
import 	Mydetail_dialog from './components/detail_dialog.vue'
Vue.component('MyTable',MyTable)
Vue.component('MydetailDialog',Mydetail_dialog)


Vue.config.productionTip = false
Vue.use(VueQuillEditor);
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
